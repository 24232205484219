.row-dragging {
  background: #fafafa;
  border: 1px solid #eee;
  td {
    padding: 16px;
  }
  .drag-visible {
    visibility: visible;
  }
}
