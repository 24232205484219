.header {
  background-color: @component-background;
  min-height: 64px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  border-bottom: 1px solid @body-background;
}
.ant-page-header {
  padding: 0;
}
.ant-page-header-heading-left {
  margin: 0;
}
