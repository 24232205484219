.logo {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 64px;
  // color: #7700ff;
  font-size: 20px;
  font-weight: bold;
  border-right: 1px solid #f0f0f0;
}
// .ant-layout-sider {
//   background: white;
// }
.ant-layout-sider-zero-width-trigger {
  top: 11px;
}

.sider-content {
  display: flex;
  flex-direction: column;
  height: 100%;
  .tag {
    margin-top: auto;
    margin-bottom: 12px;
    align-self: center;
    color: white;
  }
}
