.global-layout {
  min-height: 100%;
  .sidebar {
    z-index: 4;
    .ant-layout-sider-children {
      overflow-y: auto;
    }
  }
  .container {
    overflow-y: auto;
    position: relative;
    .fixed-top {
      position: sticky;
      top: 0;
      z-index: 3;
      .router-tabs {
        background-color: #fafafa;
        box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1);
        &.ant-tabs-card.ant-tabs-top > .ant-tabs-nav .ant-tabs-tab-active,
        .ant-tabs-card.ant-tabs-top > div > .ant-tabs-nav .ant-tabs-tab-active {
          border-bottom: 0;
          border-top: 2px solid;
        }
        &.ant-tabs-card > .ant-tabs-nav .ant-tabs-tab,
        .ant-tabs-card > div > .ant-tabs-nav .ant-tabs-tab {
          border: 0;
          border-right: 1px solid #f0f0f0;
        }
        &.ant-tabs-top > .ant-tabs-nav::before,
        .ant-tabs-bottom > .ant-tabs-nav::before,
        .ant-tabs-top > div > .ant-tabs-nav::before,
        .ant-tabs-bottom > div > .ant-tabs-nav::before {
          border-bottom: 0;
        }
      }
    }
    .ant-tabs {
      overflow: unset;
    }
  }
  .main {
    padding: 20px;
    min-height: auto;
    .rwd (sm;{padding:10px});
  }
}
